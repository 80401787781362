<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-mstar">
        <a href="https://www.mstar-shop.com/" target="_blank">
          <img src="@/assets/casestudy/4-mstar-full-onwhite.svg" alt="Mstar"></a>
        <span class="sep"></span>
        <p>The house of Mstar is a luxury clothing and accessories fashion brand owned by
          Mstar Clothing & Acc.. Their products are top quality, unique and in limited edition
          only, just like the website. They offer product personalisation so that you can make
          your product unique. You can find and shop Mstar products only on the official
          website.</p>
      </div>
    </div>

    <div class="laptop-img mstar-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/mstar-preview.png" alt="Mstar">
      </div>
    </div>

    <div class="container-about mstar-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/mstar-diamond.svg" alt="Mstar">
        <h3>About the project</h3>
        <p>An online shop where Mstar is selling its top products. They offer personalization on
          most of the products, meaning that you can actually emboss your initials on your
          product and see how it will actually look in the final stage. You have a color
          selection along with a text area for your initials, name or nickname - a cool feature
          that is meant to make your product unique.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img src="@/assets/casestudy/source-sans-pro-font.svg" alt="Mstar Font">
            <img src="@/assets/casestudy/futura-font.svg" alt="Mstar Font" class="second-font">
            <h3><span style="color:#b50b0b">Co</span><span style="color:#000000">lo</span><span
                style="color:#959595">rs</span></h3>

            <div class="colors">
              <div class="fcolor mstar-p">Primary</div>
              <div class="scolor mstar-s">Secondary</div>
              <div class="tcolor mstar-t">Third</div>
            </div>

            <!--sep SEP -->
            <span class="sep vertical color-tag"></span>
            <div class="color-codes">
              <div class="ccodes">
                <p class="mstar-primary">#b50b0b</p>
                <p class="mstar-secondary">#000000</p>
                <p class="mstar-third">#959595</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <testimonial backgroundTsColor="#b5090c">
      <div class="testimonial-text">
        <p>Flavius Trica - Owner at Mstar</p>
        <p>Scriptics team managed to develop this website based on our exact complex
          design and features. Their attention on this was top notch and the result is an amazing website that we are proud of.</p>
      </div>
    </testimonial>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>
          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Psiholog'}" class="left">
              <img src="@/assets/casestudy/btn-psiholog.png" alt="Psiholog">
            </router-link>
          </div>
        </div>

        <div class="container-nav-projects-right">
          <router-link :to="{ name: 'Startablish'}" class="right">
            <img src="@/assets/casestudy/btn-startablish.png" alt="Startablish">
          </router-link>
        </div>
      </div>
    </div>

    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Testimonial from "../../components/Testimonial";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "MStar",
  components: {LetsChat, Footer, ContactUs, Testimonial}
}
</script>

<style lang="scss">
.logo-mstar img {
  max-width: 320px;
}

.mstar-laptop {
  background-color: #b5090c;
}

.mstar-p {
  background-color: #b50b0b;
}

.mstar-primary {
  color: #b50b0b;
}

.mstar-s {
  background-color: #000000;
}

.mstar-secondary {
  color: #000000;
}

.mstar-t {
  background-color: #959595;
}

.mstar-third {
  color: #959595;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #b5090c 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>